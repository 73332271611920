/*
 * openclose toggler
 *
 * <i data-openclose-toggler data-state="open" data-target="[data-target]">click me</i>
 *
 * <div data-target>
 *    ...
 * </div>
 */
(function ($) {
    /*
     * plugin definition
     */
    $.fn.opencloseToggler = function (options) {
        var settings = $.extend({}, $.fn.opencloseToggler.defaults, options || {});

        function changeState($elm, state) {
            if (state !== undefined) {
                $elm.data('state', state);
            }
            else {
                if ($elm.data('state') === 'open') {
                    $elm.data('state', 'close');
                }
                else {
                    $elm.data('state', 'open');
                }
            }

            process($elm);
        }

        function process($elm) {
            var currentSettings = {
                state: $elm.data('state') || settings.state,
                checkboxOpenSelector: $elm.data('checkbox-open-selector') || settings.checkboxOpenSelector,
                target: $elm.data('target') || settings.target,
                stateClass: $elm.data('stateclass') || settings.stateClass,
                openStateClass: $elm.data('openstateclass') || settings.openStateClass,
                closeStateClass: $elm.data('closestateclass') || settings.closeStateClass
            }
            var state = currentSettings.state;

            if ($elm.is(':checkbox')) {
                if ($elm.is(currentSettings.checkboxOpenSelector)) {
                    state = 'open';
                }
                else {
                    state = 'close';
                }
            }

            if (state === 'open') {
                $(currentSettings.target).slideDown();
                if ($elm.find(currentSettings.stateClass)) {
                    $elm.find(currentSettings.stateClass).addClass(currentSettings.openStateClass);
                    $elm.find(currentSettings.stateClass).removeClass(currentSettings.closeStateClass);
                }
            }
            else {
                $(currentSettings.target).slideUp();
                if ($elm.find(currentSettings.stateClass)) {
                    $elm.find(currentSettings.stateClass).addClass(currentSettings.closeStateClass);
                    $elm.find(currentSettings.stateClass).removeClass(currentSettings.openStateClass);
                }
            }
        }

        return this.each(function () {
            var $this = $(this);

            // set event handler
            if ($this.is('input[type=checkbox]')) {
                $this.off('change.opencloseToggler').on('change.opencloseToggler', function () {
                    process($this);
                });
            }
            else {
                $this.off('click.opencloseToggler').on('click.opencloseToggler', function (e, state) {
                    changeState($this, state);
                });
            }

            // init
            process($this);
        });
    };

    /*
     * set default values
     */
    $.fn.opencloseToggler.defaults = {
        state: 'open',
        checkboxOpenSelector: ':checked',
        target: '[data-openclose-container]',
        stateClass: '.openclose-toggler__state',
        openStateClass: 'openclose-toggler__state--open',
        closeStateClass: 'openclose-toggler__state--close',
    };
})(jQuery);

$('[data-openclose-toggler]').opencloseToggler();


/*
 * checkbox selector
 *
 * <i class="checkbox-selector__pointer" data-checkbox-selector="table" data-checkbox-selector-action="check">check</i>
 * <i class="checkbox-selector__pointer" data-checkbox-selector="table" data-checkbox-selector-action="uncheck">uncheck</i>
 *
 */
(function ($) {
    /*
     * plugin definition
     */
    $.fn.checkboxSelector = function (options) {
        var settings = $.extend({}, $.fn.opencloseToggler.defaults, options || {});

        function process($elm) {
            var currentSettings = {
                target: $elm.data('checkbox-selector') || settings.target,
                action: $elm.data('checkbox-selector-action') || settings.action
            }
            var state = false;
            if (currentSettings.action == 'check') {
                state = true;
            }

            if (currentSettings.target == 'row') {
                $('input[type="checkbox"]', $elm.closest('tr')).each(function () {
                    $(this).prop('checked', state);
                });
            }
            else if (currentSettings.target == 'col') {
                index = $elm.closest('th').index();
                $('tbody tr td:nth-child(' + (index + 1) + ') input[type="checkbox"]', $elm.closest('table')).each(function () {
                    $(this).prop('checked', state);
                });
            }
            else {
                $('input[type="checkbox"]', $elm.closest(currentSettings.target)).each(function () {
                    $(this).prop('checked', state);
                });
            }

        }

        return this.each(function () {
            var $this = $(this);

            // set event handler
            $this.off('click.checkboxSelector').on('click.checkboxSelector', function () {
                process($this);
            });
        });
    };

    /*
     * set default values
     */
    $.fn.checkboxSelector.defaults = {
        target: 'table',
        action: 'check'
    };
})(jQuery);

$('[data-checkbox-selector]').checkboxSelector();


/*
 * display age
 *
 * <span data-display-age>01.01.1980</span>
 *
 * <span data-display-age data-target="[data-age]" data-template="(###)">01.01.1980</span>
 * <span data-age></span>
 *
 * <input data-display-age data-target="[data-age]" data-template="(###)" value="01.01.1980"/>
 * <span data-age></span>
 */
(function ($) {
    /*
     * plugin definition
     */
    $.fn.displayAge = function (options) {
        var settings = $.extend({}, $.fn.displayAge.defaults, options || {});

        function calcAge(date1, date2, format1, format2) {
            var momentDate1, momentDate2;
            var age;
            if (date1 !== undefined && date1 !== '') {
                if (format1 === undefined || format1 === '') {
                    momentDate1 = moment(new Date(date1));
                }
                else {
                    momentDate1 = moment(date1, format1);
                }

                if (date2 === undefined || date2 === '') {
                    momentDate2 = moment();
                }
                else {
                    if (format2 === undefined || format2 === '') {
                        momentDate2 = moment(new Date(date2));
                    }
                    else {
                        momentDate2 = moment(date2, format2);
                    }
                }

                age = momentDate2.diff(momentDate1, 'years');
            }
            return age;
        }

        function displayAge($elm) {
            var date1, date2;
            var age;
            var template;
            var $compareToElm;
            var currentSettings = {
                target: $elm.data('target') || settings.target,
                compareTo: $elm.data('compare-to') || settings.compareTo,
                template: $elm.data('template') || settings.template,
                format1: $elm.data('format1') || settings.format1,
                format2: $elm.data('format2') || settings.format2,
            }

            if ($elm.is('input, select, textarea')) {
                date1 = $elm.val().trim();
            }
            else {
                date1 = $elm.text().trim();
            }
            $compareToElm = $(currentSettings.compareTo);
            if ($compareToElm !== undefined) {
                if ($compareToElm.is('input, select, textarea')) {
                    date2 = $compareToElm.val().trim();
                }
                else {
                    date2 = $compareToElm.text().trim();
                }
            }

            if (date1 != '') {
                age = calcAge(date1, date2, currentSettings.format1, currentSettings.format2);

                if (age !== undefined) {
                    template = currentSettings.template;
                    age = template.replace('###', age);
                }
                else {
                    age = '';
                }

                $(currentSettings.target).each(function () {
                    if ($(this).is('input, select, textarea')) {
                        $(currentSettings.target).val(age);
                    }
                    else {
                        $(currentSettings.target).text(age);
                    }
                });
            }
        }

        return this.each(function () {
            var $this = $(this);

            // set event handlers
            $this.off('change.displayAge').on('change.displayAge', function () {
                displayAge($this);
            });
            $compareToElm = $($this.data('compare-to') || settings.compareTo);
            if ($compareToElm !== undefined) {
                if ($compareToElm.is('input, select, textarea')) {
                    $compareToElm.off('change.displayAge').on('change.displayAge', function () {
                        displayAge($this);
                    });
                    displayAge($this);
                }
            }

            // init
            displayAge($this);
        });
    };

    /*
     * set default values
     */
    $.fn.displayAge.defaults = {
        target: '[data-age]',
        compareTo: '[data-age-compare]',
        template: '(###)',
        format1: 'YYYY-MM-DD',
        format2: 'YYYY-MM-DD',
    };
})(jQuery);

$('[data-display-age]').displayAge({format1: 'DD.MM.YYYY', format2: 'DD.MM.YYYY'});


/*
 * Register dirtyforms
 */
$('[data-dirtyform]').dirtyForms().on('dirty.dirtyforms clean.dirtyforms', function (event) {

    var $this = $(this);
    var saveIconReference = $this.attr('data-dirtyform');
    var $saveIconElement = $('[data-dirtyform-save="' + saveIconReference + '"]');

    if (saveIconReference && $saveIconElement.length) {
        if (event.type === 'dirty') {
            $saveIconElement.removeClass('hide');
        } else {
            $saveIconElement.addClass('hide');
        }
    }
});

/*
 * Assign to me
 */
(function ($) {
    /*
     * plugin definition
     */
    $.fn.assignToMe = function (options) {
        function process($elm) {
            var elm = $elm.attr('data-assign-to-me');
            var id = $elm.attr('data-assign-to-me-id');
            var $target = $(elm);

            if ($target) {
                $target.val(id).trigger('change');
            }
        }

        return this.each(function () {
            var $this = $(this);

            // set event handler
            $this.off('click.assignToMe').on('click.assignToMe', function () {
                process($this);
            });
        });
    };

    /*
     * set default values
     */
    $.fn.displayAge.defaults = {
        target: '[data-age]',
        compareTo: '[data-age-compare]',
        template: '(###)',
        format1: 'YYYY-MM-DD',
        format2: 'YYYY-MM-DD',
    };
})(jQuery);

$('[data-assign-to-me]').assignToMe();

/*
 * tabs
 */
if (location.hash !== '') {
    $('a[href="' + location.hash + '"]').tab('show');
}
$('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
    if (history.pushState) {
        history.pushState(null, null, '#' + $(e.target).attr('href').substr(1));
    }
    else {
        location.hash = '#' + $(e.target).attr('href').substr(1);
    }
});

/**
 * textarea autosize
 */
$('textarea.autosize').textareaAutoSize();

/**
 * popup
 */
(function ($) {
    /*
     * plugin definition
     */
    $.fn.popup = function (options) {
        var settings = $.extend({}, $.fn.popup.defaults, options || {});

        function process($elm) {
            var currentSettings = {
                width: $elm.data('popup-width') || settings.width,
                height: $elm.data('popup-height') || settings.height,
                top: $elm.data('popup-top') || settings.top,
                left: $elm.data('popup-left') || settings.left,
                center: $elm.data('popup-center') || settings.center,
                scrollbars: $elm.data('popup-scrollbars') || settings.scrollbars,
                resizeable: $elm.data('popup-resizeable') || settings.resizeable,
                data: $elm.data('popup-data') || settings.data,
            }

            if (currentSettings.center) {
                // center popup
                var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : screen.left;
                var dualScreenTop = window.screenTop != undefined ? window.screenTop : screen.top;

                var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
                var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

                currentSettings.left = ((width / 2) - (currentSettings.width / 2)) + dualScreenLeft;
                currentSettings.top = ((height / 2) - (currentSettings.height / 2)) + dualScreenTop;
            }


            var popup = window.open($elm.prop('href') + (currentSettings.data ? '?' + $.param(currentSettings.data) : ''), '', 'height=' + currentSettings.height + ', width=' + currentSettings.width + ', top=' + currentSettings.top + ', left=' + currentSettings.left + (currentSettings.scrollbars ? ', scrollbars=yes' : '') + (currentSettings.resizeable ? ', resizeable=yes' : ''));
            popup.isPopup = true;
        }

        return this.each(function () {
            var $this = $(this);

            // set event handler
            $this.off('click.popup').on('click.popup', function (e) {
                e.preventDefault();

                process($this);
            });
        });
    };

    /*
     * set default values
     */
    $.fn.popup.defaults = {
        width: 400,
        height: 300,
        top: 100,
        left: 100,
        center: true,
        scrollbars: true,
        resizeable: true,
        data: null,
    };
})(jQuery);

$('[data-popup]').popup();

/**
 * clockpicker
 */
$('.clockpicker').clockpicker({
    placement: 'bottom',
    donetext: 'Done'
});

/**
 * prevent form data from double submission
 */
(function ($) {
    /*
     * plugin definition
     */
    $.fn.submitOnce = function (options) {
        var settings = $.extend({}, $.fn.submitOnce.defaults, options || {});

        return this.each(function () {
            var $this = $(this);

            // add icons
            $this.find('[type=submit]').prepend('<' + settings.tag + ' class="' + settings.icon + ' hide"></' + settings.tag + '>');

            // set event handlers
            $this.find('[type=submit]').off('click.submitOnce').on('click.submitOnce', function (e) {
                $this.find('[type=submit]').removeAttr('data-submit-once-click-button');
                $(this).attr('data-submit-once-click-button', 'true');
            });

            $this.off('submit.submitOnce').on('submit.submitOnce', function (e) {
                $this.find('[type=submit]').prop('disabled', true);
                var $button = $this.find('[data-submit-once-click-button=true]');
                $button.find(settings.tag).removeClass('hide');
                // add button variable, because original button is disabled
                $this.append('<input type="hidden" name="' + $button.attr('name') + '" value="' + $button.attr('value') + '"/>');
            });
        });
    };

    /*
     * set default values
     */
    $.fn.submitOnce.defaults = {
        icon: 'fa fa-circle-o-notch fa-spin',
        tag: 'i',
    };
})(jQuery);

$('[data-submit-once]').submitOnce();

/**
 * clipboard
 */
var clipboard = new Clipboard('[data-clipboard-text]');


/**
 * prevent form data from double submission
 */
(function ($) {
    $.fn.stickyBottom = function (options) {

        function clone($elm) {
            $stickyElm = $elm.clone(true);

            $stickyElm.css({
                'margin': 0
            });

            $container = $('<div class="sticky-bottom-container"></div>')

            $container.append($stickyElm).insertAfter($elm);

            return $container;
        }

        function process($elm, $container) {
            var isDocked = false;
            var scrollTop = 0;
            var scrollTopBody = $('body').scrollTop();
            var scrollTopHtml = $('html').scrollTop();

            if (scrollTopBody > 0) {
                scrollTop = scrollTopBody
            }
            else {
                scrollTop = scrollTopHtml
            }

            //var pageHeight = $(document).height();
            //var pageWidth = $(document).width();
            var viewportHeight = $(window).height();
            //var viewportWidth = $(window).width();
            var elementTop = $elm.offset().top;
            var elementLeft = $elm.offset().left;
            var elementHeight = $elm.height();
            var elementWidth = $elm.width();
            //var elementMarginBottom = $elm.css('margin-bottom');
            //var elementPaddingLeft = $elm.css('padding-left').replace('px', '');

            if (viewportHeight + scrollTop < elementTop + elementHeight) {
                isDocked = true
            }

            if (isDocked) {
                if (!$container.hasClass('sticky-bottom-docked')) {
                    $container.addClass('sticky-bottom-docked');
                }
                $container.css({
                    'padding-left': elementLeft + 'px'
                });
                $container.children().first().css({
                    'width': elementWidth + 'px'
                });
            }
            else {
                if ($container.hasClass('sticky-bottom-docked')) {
                    $container.removeClass('sticky-bottom-docked');
                }
            }
        }

        return this.each(function () {
            var $this = $(this);
            var $container;

            $container = clone($this);

            // set event handler
            $(window).off('scroll.stickyBottom, resize.stickyBottom').on('scroll.stickyBottom, resize.stickyBottom', function (e) {
                process($this, $container);
            });
        });
    };
})(jQuery);

$('[data-sticky-bottom]').stickyBottom();

(function () {
    function checkCondition(v1, operator, v2) {
        switch (operator) {
            case '==':
                return (v1 == v2);
            case '===':
                return (v1 === v2);
            case '!==':
                return (v1 !== v2);
            case '<':
                return (v1 < v2);
            case '<=':
                return (v1 <= v2);
            case '>':
                return (v1 > v2);
            case '>=':
                return (v1 >= v2);
            case '&&':
                return (v1 && v2);
            case '||':
                return (v1 || v2);
            default:
                return false;
        }
    }

    Handlebars.registerHelper('ifCond', function (v1, operator, v2, options) {
        return checkCondition(v1, operator, v2)
            ? options.fn(this)
            : options.inverse(this);
    });
}());

(function ($) {
    /*
     * plugin definition
     */
    $.fn.dataFilter = function (options) {
        return this.each(function () {
            var $this = $(this);

            if ($($this.attr('data-filter-target')).length) {
                if (!$($this.attr('data-filter-target')).is(':visible')) {
                    $this.show();
                }

                $this.off('click.dataFilter').on('click.dataFilter', function (e) {
                    if (!$($this.attr('data-filter-target')).is(':visible')) {
                        $($this.attr('data-filter-target')).slideDown();
                        $this.hide();
                    }
                });
            }
        });
    };
})(jQuery);

$('[data-filter-button]').dataFilter();

(function ($) {
    /*
     * plugin definition
     */
    $.fn.dataCloneValue = function (options) {
        return this.each(function () {
            var $this = $(this);

            $this.off('click.dataCloneValue').on('click.dataCloneValue', function (e) {
                $prevElm = $('#' + $this.attr('data-clone-value') + '_begin');
                $nextElm = $('#' + $this.attr('data-clone-value') + '_end');

                $nextElm.val($prevElm.val());
            });
        });
    };
})(jQuery);

$('[data-clone-value]', 'form').dataCloneValue();

/*
 * Vue components
 */
Vue.component('select2', {
    props: {
        options: {
            type: Array,
            default: [],
        },
        value: {
            type: [String, Array, Boolean, Number],
        },
        multiple: {
            type: Boolean,
            default: false
        },
        allowClear: {
            type: Boolean,
            default: false
        },
    },
    template: '<input style="width: 100%">',
    mounted: function () {
        var vm = this
        $(this.$el)
            .val(this.value)
            // init select2
            .select2({
                data: this.options,
                multiple: this.multiple,
            })
            // emit event on change.
            .on('change', function () {
                if (this.value == '') {
                    this.value = null;
                }
                vm.$emit('input', this.value)
            })
    },
    watch: {
        value: function (value) {
            // update value
            if (value === true) {
                value = '';
            }
            $(this.$el).val(value).trigger('change');
        },
        options: function (options) {
            // update options
            $(this.$el).select2({
                data: options
            })
        }
    },
    destroyed: function () {
        $(this.$el).off().select2('destroy')
    }
})

Vue.component('date-input', {
    template: '\
      <input\
        ref="input"\
        v-bind:value="value"\
        v-on:change="updateValue($event.target.value)"\
        v-on:blur="formatValue"\
      >\
    ',
    props: {
        value: {
            type: String,
            default: '',
        },
        format: {
            type: String,
            default: null,
        },
    },
    mounted: function () {
        this.formatValue();
    },
    methods: {
        updateValue: function (value) {
            var m = moment(value, this.format);
            if (m.isValid()) {
                var dateValue = m.format('YYYY-MM-DD');
                this.$refs.input.value = this.formatValue(dateValue);
                this.$emit('input', dateValue);

            }
            else {
                console.log(value + ' is not a valid date (format: ' + this.format + ')');
            }
        },
        formatValue: function () {
            var m = moment(this.value, 'YYYY-MM-DD');
            if (m.isValid()) {
                this.$refs.input.value = m.format(this.format);
            }
        },
    }
});

Vue.component('datepicker', {
    props: {
        value: {
            type: String,
            default: '',
        },
        format: {
            type: String,
            default: null,
        },
    },
    template: '\
      <input\
        ref="input"\
        v-bind:value="value"\
        v-on:change="updateValue($event.target.value)"\
        v-on:blur="formatValue"\
        style="width: 100%"\
      >\
    ',
    activated: function () {
        console.log('activated');
    },
    mounted: function () {
        var formattedValue = this.formatValue();

        var vm = this;
        $(this.$el)
            .val(formattedValue)
            // init datepicker
            .datepicker({
                dateFormat: 'dd.mm.yy',
                prevText: '<i class="fa fa-chevron-left"></i>',
                nextText: '<i class="fa fa-chevron-right"></i>',
                onSelect: function (value, el) {
                    vm.updateValue(value);
                },
            })
    },
    watch: {
        value: function (value) {
            var formattedValue = this.formatValue();

            if (formattedValue) {
                // update value
                $(this.$el).val(formattedValue)

                this.$nextTick(function () {
                    this.$refs.input.value = formattedValue;
                })
            }
        }
    },
    methods: {
        updateValue: function (value) {
            var m = moment(value, this.format);
            if (m.isValid()) {
                var dateValue = m.format('YYYY-MM-DD');
                this.$refs.input.value = this.formatValue(dateValue);
                this.$emit('input', dateValue);
            }
            else {
                console.log(value + ' is not a valid date (format: ' + this.format + ')');
            }
        },
        formatValue: function () {
            var m = moment(this.value, 'YYYY-MM-DD');
            if (m.isValid()) {
                var formattedValue = m.format(this.format);
                this.$refs.input.value = formattedValue;
                return formattedValue;
            }
        },
    },
    destroyed: function () {
        $(this.$el).off().datepicker('destroy')
    }
})

Vue.component('clockpicker', {
    props: {
        value: {
            type: String,
            default: '',
        },
        format: {
            type: String,
            default: null,
        },
    },
    template: '\
      <input\
        ref="input"\
        v-bind:value="value"\
        v-on:change="updateValue($event.target.value)"\
        v-on:blur="formatValue"\
        style="width: 100%"\
      >\
    ',
    mounted: function () {
        var formattedValue = this.formatValue();

        var vm = this;
        $(this.$el)
            .val(formattedValue)
            // init clockpicker
            .clockpicker({
                placement: 'bottom',
                autoclose: true,
            })
            .on('change', function() {
                vm.updateValue(this.value);
            })
    },
    watch: {
        value: function (value) {
            var formattedValue = this.formatValue();

            if (formattedValue) {
                // update value
                $(this.$el).val(formattedValue)

                this.$nextTick(function () {
                    this.$refs.input.value = formattedValue;
                })
            }
        }
    },
    methods: {
        updateValue: function (value) {
            var m = moment(value, this.format);
            if (m.isValid()) {
                var dateValue = m.format('HH:mm:ss');
                this.$refs.input.value = this.formatValue(dateValue);
                this.$emit('input', dateValue);
            }
            else {
                console.log(value + ' is not a valid time (format: ' + this.format + ')');
            }
        },
        formatValue: function () {
            var m = moment(this.value, 'HH:mm:ss');
            if (m.isValid()) {
                var formattedValue = m.format(this.format);
                this.$refs.input.value = formattedValue;
                return formattedValue;
            }
        },
    },
    destroyed: function () {
        $(this.$el).remove();
    }
});

accounting.settings = {
    currency: {
        symbol : "€",   // default currency symbol is '$'
        format: "%v %s", // controls output: %s = symbol, %v = value/number (can be object: see below)
        decimal : ",",  // decimal point separator
        thousand: ".",  // thousands separator
        precision : 2   // decimal places
    },
    number: {
        precision : 2,
        thousand: ".",
        decimal : ","
    }
}

Vue.component('number-input', {
    props: {
        value: {
            type: [Number, String],
            default: null,
        },
    },
    template: '\
      <input\
        ref="input"\
        v-bind:value="value"\
        v-on:change="updateValue($event.target.value)"\
        v-on:blur="formatValue"\
        style="width: 100%"\
      >\
    ',
    mounted: function () {
        this.formatValue();
    },
    watch: {
        value: function (value) {
            this.formatValue();
        }
    },
    methods: {
        updateValue: function (value) {
            value = _.trim(value);
            if (value != '') {
                var v = accounting.parse(value);
                if (v) {
                    this.$emit('input', v);
                }
                else {
                    console.log(value + ' is not a valid number');
                }
            }
            else {
                this.$emit('input', null);
            }
        },
        formatValue: function () {
            if (this.value) {
                var formattedValue = accounting.formatNumber(this.value);
                this.$refs.input.value = formattedValue;
                return formattedValue;
            }
            return '';
        },
    },
});

function debounce(func, wait, immediate) {
    var timeout;
    return function () {
        var context = this, args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};